import '@picocss/pico/css/pico.min.css';
import { initializeApp } from 'firebase/app';
import { AuthService } from '@magickainteractive/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCj6olj5kvdGhl32pJlxL8wq20oxIWWmpo',
    authDomain: 'auth.mgka.net',
    projectId: 'slam4edu',
    storageBucket: 'slam4edu.appspot.com',
    messagingSenderId: '640905549057',
    appId: '1:640905549057:web:f40761c0d98d8cda48b700'
};
const app = initializeApp(firebaseConfig);

const authConfig = {
    apiEndpoint: 'https://api.mgka.net',
    accountDomain: 'https://account.mgka.net',
    redirectUri: 'https://testapp.mgka.net',
    appId: 'BbRvRQKZ6H5I5rKa48ra',
    firebaseApp: app,
};
const authService = new AuthService(authConfig);

document.addEventListener('DOMContentLoaded', async () => {
    console.log('hello world!');

    await authService.doAuth().then(user => {
        console.log(user);

        document.getElementById('progress').style.display = 'none';
        // name & email - if no name, just email
        document.getElementById('user-info').innerHTML = user.displayName ? `${user.displayName} (${user.accessRole}) (${user.email})` : user.email;
    }).catch(err => {
        console.error(err);
    });
});