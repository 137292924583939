var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAuth, signInWithCustomToken } from "firebase/auth";
export class AuthService {
    /**
     * Creates an instance of AuthService.
     * @param {AuthConfig} config - The configuration object for authentication.
     */
    constructor(config) {
        this.config = config; // use the defined config
        this.auth = getAuth(this.config.firebaseApp); // initalise firebase auth with the app-specific firebase app
    }
    /**
     * Initiates the authentication process.
     * @returns {Promise<User>} A promise that resolves to a Firebase User object.
     * @throws Will throw an error if the authentication process fails.
     */
    doAuth() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Starting auth process");
            try {
                const token = yield this.fetchFirebaseToken(); // try and get a token from the API, using the session cookie
                return yield this.signInWithFirebaseToken(token); // we have a token, so sign in with it
            }
            catch (error) {
                // likely no session cookie and thus no token, so need to re-auth at the account domain
                console.error("Authentication process failed:", error);
                throw error;
            }
        });
    }
    /**
     * Fetches the Firebase token from the API using the session cookie.
     * @returns {Promise<string>} A promise that resolves to a Firebase token string.
     * @throws Will throw an error if the token fetch fails and a redirect is required.
     */
    fetchFirebaseToken() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Fetching Firebase token");
            const response = yield fetch(`${this.config.apiEndpoint}/auth/get-token`, {
                method: "GET",
                credentials: "include",
            });
            // all good - return the token to login with
            if (response.ok) {
                console.log("Firebase token fetched");
                const data = yield response.json();
                return data.firebaseToken;
            }
            else {
                // error - redirect to login
                window.location.href = `${this.config.accountDomain}/?redirect=${this.config.redirectUri}`;
                throw new Error("Redirect required");
            }
        });
    }
    /**
     * Signs into Firebase using this app's auth object and the Firebase token.
     * @param {string} token - The Firebase token.
     * @returns {Promise<User>} A promise that resolves to a Firebase User object.
     * @throws Will throw an error if signing in with the Firebase token fails.
     */
    signInWithFirebaseToken(token) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Signing in with Firebase token");
            try {
                const userCredential = yield signInWithCustomToken(this.auth, token);
                console.log("Signed in with Firebase token");
                // check if user has access to the app (uses session cookie to get user ID)
                const accessRole = yield this.checkAppAccess();
                if (!accessRole) {
                    throw new Error("User does not have access to this app");
                }
                // Create a new object that includes the user and the access role
                const userWithAccessRole = Object.assign(Object.assign({}, userCredential.user), { accessRole: accessRole });
                // Return the user with access role
                return userWithAccessRole;
            }
            catch (error) {
                console.error("Failed to sign in with Firebase token:", error);
                throw error;
            }
        });
    }
    /**
     * Checks if the user has access to the app.
     * @returns {Promise<AccessRole | null>} A promise that resolves to the user's access role, or null if the user does not have access.
     * @throws Will throw an error if the user has no access.
     */
    checkAppAccess() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            try {
                // Make a request to the API /check-access endpoint
                const response = yield fetch(`${this.config.apiEndpoint}/auth/check-access`, {
                    method: "POST",
                    credentials: "include", // include cookies so backend can know what user
                    headers: {
                        "Content-Type": "application/json",
                    },
                    // provide target app ID, user ID is inferred from the session cookie
                    body: JSON.stringify({
                        appId: this.config.appId,
                    }),
                });
                const data = yield response.json();
                console.log('API Response:', data);
                if (response.ok) {
                    // Ensure the role is present in the response
                    return (_a = data.role) !== null && _a !== void 0 ? _a : null;
                }
                else {
                    // error, or user doesn't have access
                    throw new Error((_b = data.error) !== null && _b !== void 0 ? _b : "User does not have access to this app");
                }
            }
            catch (error) {
                console.error('Error in checkAppAccess:', error);
                throw error;
            }
        });
    }
}
